const gameData = [
  {
    "game": "acquire",
    "label": "Acquire",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/acquire/",
    "qDate": "09/29/22, 10:30 AM",
    "seats": 256,
    "rounds": 4,
    "competition": "Ring Event Competitors will play “classic” Acquire, meaning any version printed between 1964 and 2015. The latest Hasbro Edition, with new payout rules and new board dimensions, may not be used. A player’s money and stocks will be private. Seating will be determined randomly. Starting player will be determined through the normal drawing of tiles, with the player in seat 1 drawing first. In case of a tie, the closest tying player to the right of the starting player will advance. Any modifications to rules and/or procedures will be finalized and posted by July 1, 2022.",
    "description": "Players are investors attempting to be the majority shareholder in their corporations. As businesses grow, players can merge them and earn sizable bonuses. The player with the most wealth at the end of the game is the winner. View the full Acquire rule book."
  },
  {
    "game": "azul",
    "label": "Azul",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/azul/",
    "qDate": "09/30/22, 3:00 PM",
    "seats": 1024,
    "rounds": 5,
    "competition": "Ring Event Competitors will play the Azul base game with NO expansions. Seating/start player will be determined randomly. Players should be prepared to play either side of the board. In the case of a tie, the player with the most complete rows will win. In the case of a further tie, the tying player in the lowest seat number (seat 4 in a four-player game, etc.) will advance. Further modifications to game rules and/or procedures will be announced by July 1, 2022.",
    "description": "Players are tile-laying artists commissioned to decorate the Royal Palace of Evora in Portugal. Tiles are chosen strategically; points are earned as tiles are placed on the palace wall and lost when tiles are wasted. The player with the most points at the end of the game is the winner. View the full Azul rule book."
  },
  {
    "game": "blood-rage",
    "label": "Blood Rage",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/blood-rage/",
    "qDate": "10/01/22, 9:00 AM",
    "seats": 256,
    "rounds": 4,
    "competition": "Ring Event Competitors will play using the Blood Rage base game and NO expansions. Seating/start player will be chosen randomly. In the case of a tie, the player with the most figures left on the board will win. If still tied, the player in the lowest number seat (seat 4 in a four-player game) will advance. Any further modifications to rules and/or procedures will be finalized and posted by July 1, 2022.",
    "description": "Players are Viking clan leaders who must earn the most glory they can as the end of the world approaches, commanding their warriors in battle against other clans, navigating the actions of the gods and securing a place in Valhalla. The clan with the most glory at the end of the game is the winner. View the full Blood Rage rule book."
  },
  {
    "game": "brass-birmingham",
    "label": "Brass Birmingham",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/brass-birmingham/",
    "qDate": "09/29/22, 9:30 AM",
    "seats": 256,
    "rounds": 4,
    "competition": "Ring Event Competitors will play the Brass: Birmingham base game. Seating/start player will be determined randomly. Tie-breakers are, in order: highest income, most money, and finally, lowest seat number (seat 4 in a four-player game). Any modifications to rules and/or procedures will be finalized and posted by July 1, 2022.",
    "description": "Players are entrepreneurs in Birmingham, England, during the Industrial Revolution. They must establish and develop industries, build canals and railways, and attempt to manipulate the market. The player with the most victory points at the end of the game is the winner. View the full Brass: Birmingham rule book."
  },
  {
    "game": "carcassonne",
    "label": "Carcassonne",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/carcassonne/",
    "qDate": "10/01/22, 3:30 PM",
    "seats": 1024,
    "rounds": 5,
    "competition": "Ring Event Competitors will play using the Carcassonne base game and NO expansions. Seating/start player will be randomly determined. Farmers will be scored by field, earning three points for every adjacent completed city. In five-player games, two tiles will be removed from the pool to ensure that all players have the same number of plays. These tiles will be removed prior to play and shown to all players. At game’s end, if multiple players’ scores are tied, the player with the most unused meeples before end-of-game scoring shall advance. If there is still a tie, the tying player in the lowest seat number (seat 5 in a five-player game, etc.) will advance. Any further modifications to rules or play will be announced by July 1, 2022.",
    "description": "Players develop the area around Carcassonne, a real-life medieval citadel in the South of France. The player who gains the most points as they fill out the landscape with roads, cities, monasteries and people is the winner. View the full Carcassonne rule book."
  },
  {
    "game": "catan",
    "label": "Catan",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/catan/",
    "qDate": "10/01/22, 7:30 AM",
    "seats": 1024,
    "rounds": 5,
    "competition": "Ring Event Competitors will play using the Catan base game and NO expansions. Development card decks will all be of the five victory point variety—not any edition containing six. Seating will be determined randomly. Seat 1 will place first, and so on. Any modifications to rules and/or procedures will be finalized and posted by July 1, 2022.",
    "description": "Players are settlers on the island of Catan. They build settlements, buildings, roads and an army by collecting, trading and spending resources. The player with the most victory points at the end of the game is the winner. View the full Catan rule book and almanac of terms."
  },
  {
    "game": "dominant-species-marine",
    "label": "Dominant Species Marine",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/dominant-species-marine/",
    "qDate": "09/29/22, 10:00 AM",
    "seats": 256,
    "rounds": 4,
    "competition": "Ring Event Competitors will play the Dominant Species: Marine base game. Draw order will be determined randomly, and the board will be seeded with a standard setup for a standard length game. All four animal cards and six random trait cards will be placed on the table. The first player in draw order will choose either an animal card or a trait card to keep. Then the second, third and fourth players in draw order will do the same. Then, in snake-draft fashion, the player who made the last selection will choose a card of the type not previously chosen. (If a trait card was taken first, they must take an animal card with their second pick.) After all players have both an animal card and a trait card, any unchosen cards will be discarded, animal cards will be placed on their preprinted side, and play will begin. Any further modifications to rules and/or procedures will be finalized and posted by July 1, 2022.",
    "description": "Each player represents one of four animal classes: reptiles, fish, cephalopods or crustaceans. A great ice age has just ended, and the creatures are adapting to as many habitats as they can in hopes of becoming the dominant species—before an asteroid ends the game. The player with the most victory points at the end of the game is the winner. View the full Dominant Species: Marine rule book."
  },
  {
    "game": "dune-imperium",
    "label": "Dune Imperium",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/dune-imperium/",
    "qDate": "10/01/22, 12:30 PM",
    "seats": 256,
    "rounds": 4,
    "competition": "Ring Event Competitors will play using the Dune: Imperium base game and NO expansions. Seating/starting player will be determined randomly. The player who is last in turn order will choose their leader first, continuing backward to the start player, who chooses their leader last. In the case of a tie, tie-breakers are, in order: the most spice, the most solari, the most water, the most garrisoned troops, and finally, the tied player taking the latest reveal action in the game. Any further modifications to rules and/or procedures will be finalized and posted by July 1, 2022.",
    "description": "Each player is a leader of one of the Great Houses of the Landsraad. They draw cards and make decisions in an effort to increase their influence, gain military power, form alliances and evolve their strategy—all to achieve and maintain control of resources, of which spices are the most valuable. The player with the most victory points at the end of the game is the winner. View the full Dune: Imperium rule book."
  },
  {
    "game": "gaia-project",
    "label": "Gaia Project",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/gaia-project/",
    "qDate": "09/30/22, 7:30 AM",
    "seats": 256,
    "rounds": 4,
    "competition": "Ring Event Competitors will play the Gaia Project base game. Seating/start player will be determined randomly. Competitors will use the Advanced Setup seen on page 19 of the rule book and variable turn order. The last player will set up the game board using Variant Method 1. In the case of a tie, the first player to end their turn in the last round will win. Any further modifications to rules and/or procedures will be finalized and posted by July 1, 2022.",
    "description": "Players are leaders of factions who are colonizing and developing planets in the Terra Mystica galaxy. Each faction has its own set of needs, leading them to terraform planets for their own habitation. Players make choices and evolve their own strategy for expanding their power and influence in the galaxy. The player with the most victory points at the end of the game is the winner. View the full Gaia Project rule book."
  },
  {
    "game": "great-western-trail",
    "label": "Great Western Trail",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/great-western-trail/",
    "qDate": "09/30/22, 8:00 AM",
    "seats": 256,
    "rounds": 4,
    "competition": "Ring Event Competitors will play the Second Edition of Great Western Trail with NO expansions. Seating/start player will be determined randomly. Simmental cattle will not be used. In the case of a tie, the player in the lowest seat number (seat 4 in a four-player game) will advance.",
    "description": "Players are 19th-century American ranchers who must send their cattle by rail from Texas to Kansas City. They earn points by making wise business decisions such as hiring skilled cowboys and engineers, building useful structures along the train route, and successfully transporting and selling their herds. The player with the most victory points at the end of the game wins. View the full Great Western Trail rule book."
  },
  {
    "game": "ra",
    "label": "Ra",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/ra/",
    "qDate": "09/30/22, 12:30 PM",
    "seats": 256,
    "rounds": 4,
    "competition": "Ring Event Competitors will play the Ra base game. Seating will be determined randomly. Starting player will be determined through the normal drawing of tiles, with the player in seat 1 drawing first. After each player has drawn, the player with the highest-numbered sun disk takes the first turn. In the case of a tie, the tied player with the highest-numbered sun disk will win the game. Any modifications to rules and/or procedures will be finalized and posted by July 1, 2022.",
    "description": "Players are royal advisors to Egyptian pharaohs. They attempt to gain power by advancing civilization in Egypt and glorifying the sun god, Ra. They earn points by constructing buildings and monuments, farming, and promoting culture and technology. The player with the most points at the end of the third round is the winner. View the full Ra rule book."
  },
  {
    "game": "splendor",
    "label": "Splendor",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/splendor/",
    "qDate": "09/28/22, 5:00 PM",
    "seats": 432,
    "rounds": 5,
    "competition": "Ring Event Competitors will play the Splendor base game with NO expansions. Seating/starting player will be determined randomly. In the case of a tie, the player who has purchased the fewest development cards will win. In case of another tie, the tying player in the lowest seat number (seat 4 in a four-player game, etc.) will advance. Any modifications to rules and/or procedures will be finalized and posted by July 1, 2022.",
    "description": "Each player is a Renaissance merchant hoping to increase their prestige by mining, transporting and selling gems. When they reach a certain echelon, players can also gain prestige by associating with nobles. The player with the most prestige points at the end of the last round is the winner. View the full Splendor rule book."
  },
  {
    "game": "terraforming-mars",
    "label": "Terraforming Mars",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/terraforming-mars/",
    "qDate": "10/01/22, 8:00 AM",
    "seats": 400,
    "rounds": 4,
    "competition": "Ring Event Competitors will play using the Terraforming Mars base game and Prelude expansion. Seating/start player will be determined randomly. Two base game corporations and one prelude corporation will be dealt to each player. These cards will NOT be drafted. Five project cards will be dealt out and drafted left; then five project cards will be dealt out and drafted right. Four prelude cards will then be dealt to each player, drafted left. At this point, players choose one corporation, two preludes and any project cards they wish to pay for and keep. In the case of a tie, the tying player with the most money will advance. If still tied, the tied player in the lowest seat number (seat 5 in a five-player game) will advance. Any further modifications to rules and/or procedures will be finalized and posted by July 1, 2022.",
    "description": "Players are leaders of corporations attempting to terraform the planet Mars in the 25th century. Players earn points by starting and completing projects that will make Mars habitable and generally advancing settlement throughout the solar system. The player with the highest Terraform Rating and most victory points at the end of the game is the winner. View the full Terraforming Mars rule book."
  },
  {
    "game": "ticket-to-ride",
    "label": "Ticket to Ride",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/ticket-to-ride/",
    "qDate": "09/30/22, 8:30 AM",
    "seats": 1024,
    "rounds": 5,
    "competition": "Ring Event competitors will play the original Ticket to Ride base game with NO expansions. Seating/starting player will be determined randomly. If two or more players are tied at game’s end, the tying player who completed the most tickets will advance. If there is still a tie, the tying player in the lowest seat number (seat 5 in a five-player game, etc.) will advance. Any further modifications to rules or play will be announced by July 1, 2022.",
    "description": "Players are friends who are trying to travel by train to as many North American cities as they can in seven days. They build train routes by drawing cards that display different types of train cars, connect cities and attempt to complete the Longest Continuous Path on the game board. Players earn points by achieving these goals, and the player with the most points at the end of the last round wins. View the full Ticket to Ride rule book."
  },
  {
    "game": "wingspan",
    "label": "Wingspan",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/wingspan/",
    "qDate": "09/29/22, 9:00 AM",
    "seats": 400,
    "rounds": 4,
    "competition": "Ring Event Competitors will play the Wingspan base game with NO expansions. Swift Start cards will not be used. Games will use the green side of the end-of-round goals card. Seating/starting player will be determined randomly. In the case of a tie, the player with the most remaining food tokens will win. The next tie-breaker will be the lowest seat number (seat 5 in a five-player game, etc.) of the tying players. Any further rules and/or procedural changes will be posted before July 1, 2022.",
    "description": "Players are bird enthusiasts trying to attract a variety of birds to their aviaries. Players draw goals and choose birds, adding them to their aviaries and earning points based on the goals drawn for each round. At the end of four rounds, the player with the most points wins. View the full Wingspan rule book."
  },
  {
    "game": "wonders",
    "label": "7 Wonders",
    "wsbgUrl": "https://wsbgvegas.com/ring-event-profile/wonders/",
    "qDate": "09/28/22, 4:00 PM",
    "seats": 400,
    "rounds": 4,
    "competition": "Ring Event Competitors will play the Second Edition of 7 Wonders, using the Second Edition Leaders expansion. Seating and Wonder boards will be determined randomly. Players must select which side of their Wonder board to play before drafting leaders. Only standard leader cards will be used. In the case of a tie, the player with the most treasure will win. In the case of another tie, the tied player with the leader in play whose name comes first alphabetically will win. Any modifications to game rules and/or procedures will be finalized and posted by July 1, 2022.",
    "description": "Each player leads one of the great cities of Antiquity through three Ages, making improvements and developing civilization and culture through acquisition of resources, scientific advancements, military victories and architecture. The player who collects the most victory points from their accomplishments is the winner. View the full 7 Wonders rule book and the Leaders expansion."
  }
]

export default gameData;