import React from 'react';
import { sortButtonTypes, SortButton } from './sortButton'

export default function Header(props) {
  const {sort, updateSort} = props;

  return (
    <div className="app-header">
      <div className="header">WSBG Training Status </div>
      <div className='sortBtn-container'>
      {Object.keys(sortButtonTypes).map((type) => (
        <SortButton key={type}
                    current={type === sort}
                    type={type}
                    onClick={() => updateSort(type)}/>
      ))}
      </div>
    </div>
  )
}