import React, { useEffect } from 'react';
import { useTable, useSortBy, useExpanded } from 'react-table';
import GameModal from '../GameModal';
import GameInfo from '../GameInfo';
import './table.css';

const dateOpts = {month: 'short', day: 'numeric'};
const calcWinPct = ({outcomes}) => {
  const pct = outcomes.won * 100 / outcomes.total

  return isNaN(pct) ? '--' : Math.floor(pct);
}

const calcPlayers = ({value, data}) => data
  .map(({game, outcomes}) => [game, outcomes.players])
  .reduce((playerMap, [game, players]) => {
    Object.entries(players).forEach(([p, weight]) => {
      if (!playerMap[p])
        playerMap[p] = {selected: false, weight: 0};
      playerMap[p].weight += game === value ? weight : 1;
    })
    return playerMap;
  }, {me: {selected: false, weight: 100}});

const columns = [
  {
    id: 'alphabet',
    Header: 'Title',
    accessor: 'label', // accessor is the "key" in the data
    Cell: (original) => (
      <GameModal {...original}
                 potentials={calcPlayers(original)}
                 currentNotes={original?.row?.original?.currentNotes}
                 gameKey={original?.row?.original?.game}
      />
    )
  },
  {
    Header: '# Games',
    accessor: ({outcomes}) => outcomes.total || '--',
  },
  {
    Header: 'Win %',
    accessor: calcWinPct,
  },
  {
    id: 'date',
    Header: 'Qualifier Game',
    sortType: 'datetime',
    accessor: ({qDate}) => new Date(qDate),
    Cell: ({value}) => value.toLocaleDateString('en-US', dateOpts),
  }
];

export default function Table(props) {
  const {sort, data, gameDispatch} = props;

  const tableInstance = useTable({columns, data}, useSortBy, useExpanded);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    prepareRow,
    setSortBy,
  } = tableInstance

  useEffect(() => setSortBy([{id: sort}]), [sort, setSortBy]);

  const expanderClick = (target) => {
    target.toggleRowExpanded();
  }

  return (
    <table {...getTableProps()}>
      <thead>
      {headerGroups.map(headerGroup => (
        <tr {...headerGroup.getHeaderGroupProps()}>
          {headerGroup.headers.map(column => (
            <th {...column.getHeaderProps()}>
              {column.render('Header')}
            </th>
          ))}
        </tr>
      ))}
      </thead>
      <tbody {...getTableBodyProps()}>
      {rows.map(row => {
        prepareRow(row)
        return (
          <React.Fragment key={row.id}>
          <tr {...row.getToggleRowExpandedProps()} onClick={() => expanderClick(row)}>
            {
              row.cells.map(cell => (
                <td {...cell.getCellProps()}>
                  {// Render the cell contents
                    cell.render('Cell', {gameDispatch})}
                </td>
              ))
            }
          </tr>
            {row.isExpanded && <tr><td colSpan='4'><GameInfo game={row.original?.game}/></td></tr>}
          </React.Fragment>
        )
      })}
      </tbody>
    </table>
  )
}