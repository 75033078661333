import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';

export default function GameNotes(props) {

  return (
    <React.Fragment>
      <FormLabel>Notes:</FormLabel>
      <Box>
        <TextField multiline
                   fullWidth
                   rows='6'
                   {...props} />
      </Box>
    </React.Fragment>
  );
}