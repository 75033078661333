import React from 'react';
import TextField from '@mui/material/TextField';
import Box from '@mui/material/Box';
import FormLabel from '@mui/material/FormLabel';

export default function Scores(props) {
  const {gameEnd: {scores}, onChange} = props;

  const handleChange = ({target}) => onChange('scores', target.value.split(','))

  return (
    <React.Fragment>
      <FormLabel>Scores:</FormLabel>
      <Box>
        <TextField multiline
                   fullWidth
                   value={scores.join(',')}
                   onChange={handleChange} />
      </Box>
    </React.Fragment>
  );
}