import React, {useState, useCallback, useContext} from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Typography from '@mui/material/Typography';
import { GameState, GameNotes, Players, Scores } from './index';
import { gameActions } from '../gameApi';
import UserContext from '../userContext';
import './gameModal.css';

export default function GameModal(props) {
  const {value, gameKey, potentials, currentNotes, gameDispatch} = props;
  const [showModal, setShowModal] = useState(false);
  const [gameEnd, setGameEnd] = useState({players: [], scores: []});
  const [gameNotes, setGameNotes] = useState(currentNotes);
  const user = useContext(UserContext);

  const updateGameEnd = (updateType, update) => {
    setGameEnd(r => ({
      ...r,
      [updateType]: update,
    }))
  }

  const updateGameNotes = ({target}) => {
    setGameNotes(target.value)
  }

  const unzipGameEnd = useCallback(({players = [], scores = []}) => {
    const toUnzip = players.map((p,i) => [p, scores[i]]);
    return toUnzip.reduce((zipObj, playerScores) => {
      zipObj[playerScores[0]] = Number(playerScores[1]);
      return zipObj;
    }, {});
  },[])

  const handleClose = useCallback(() => setShowModal(false), [setShowModal]);
  const saveAndExit = useCallback((ends, notes) => {
    if(user.permissions === 'RW') {
      const scores = unzipGameEnd(ends);
      const saveData = {
        game: gameKey,
        date: new Date(),
        scores,
      }
      const outcomeUpdate = {
        game: gameKey,
        iWon: Object.keys(scores).filter(k => k !== 'me').every(k => scores[k] < scores.me),
        playerSet: ends.players,
      }
      gameDispatch(gameActions.addGame(saveData));
      gameDispatch(gameActions.updateOutcomes(outcomeUpdate));
    } else {
      gameDispatch(gameActions.saveNotes(gameKey, notes));
    }
    setGameEnd({players: [], scores: []});
    handleClose();
  }, [handleClose, setGameEnd, gameDispatch, unzipGameEnd, gameKey, user.permissions])

  const openModalClick = () => setShowModal(sm => !sm);

  return (
    <div onClick={(e) => e.stopPropagation()}>
      <Button variant='outlined'
              className='game-name'
              size='large'
              onClick={openModalClick}>{value}</Button>
      <Dialog
        open={showModal}
        onClose={handleClose}
        aria-labelledby='game-modal-title'
        aria-describedby='game-modal-description'
      >
        <DialogTitle id='game-modal-title'>
          Game - {value}
        </DialogTitle>
        <DialogContent>
          <Typography id='game-modal-description' sx={{mt: 2}}>
            Create a new game entry:
          </Typography>
          {user?.permissions === 'RW' ? (
            <>
              <GameState gameEnd={gameEnd}/>
              <Scores gameEnd={gameEnd} onChange={updateGameEnd}/>
              <Players gameEnd={gameEnd} onChange={updateGameEnd} potentials={potentials}/>
            </>
          ) : (
            <GameNotes value={gameNotes} onChange={updateGameNotes}/>
          )
          }
        </DialogContent>
        <DialogActions>
          <Button onClick={() => saveAndExit(gameEnd, gameNotes)}>Save</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}