import { getFirestore, collection, doc, getDocs, setDoc, query, where } from 'firebase/firestore';
import {getAuth} from 'firebase/auth';
import { getApp } from './firebase'

import {
  FETCH_GAMES_START,
  FETCH_GAMES_SUCCESS,
  ADD_OUTCOME
} from './constants';

let user = {}
getAuth().onAuthStateChanged((u) => user = u);

const db = getFirestore()

const loadCollection = () => {
  return collection(db, 'games');
}

// Get a list of games from the database
// async function getGames() {
//   const gamesCollection = loadCollection();
//   const gameSnapshot = await getDocs(gamesCollection);
//   const gameList = gameSnapshot.docs.map(doc => doc.data());
//   return gameList;
// }

// Get a list of outcomes from the database
async function getOutcomes() {
  const outcomesSnapshot = await getDocs(collection(db, 'outcomes'));
  return outcomesSnapshot.docs.reduce((outcomes, doc) => ({
    ...outcomes,
    [doc.id]: doc.data()
  }), {});
}

async function getNotes() {
  let q = collection(db, 'notes');
  if(!user || user.permissions === 'RO') {
    q = query(q, where('uid','==',(user || {}).uid));
  }

  try {
    const notesSnapshot = await getDocs(q)
    return notesSnapshot.docs.reduce((notes, doc) => {
      const note = doc.data();
      notes[note.game] = note.note;
      return notes;
    }, {})
  } catch (err) {
    //No problem, they have no notes
    return {};
  }
}

async function saveGame(game) {
  const gamesCollection = loadCollection()
  await setDoc(doc(gamesCollection), game);
}

// Get a list of games from the database
async function saveOutcomes(game, outcomes) {
  await setDoc(doc(db, 'outcomes', game), outcomes);
}

async function saveNotes(game, note) {
  await setDoc(
    doc(db, 'notes', `${game}:${user.uid}`),
    {note, uid: user.uid, game}
  );
}

const gameApi = {
  getOutcomes: async () => {
    return await getOutcomes();
  },
  saveGameData: async (game) => {
    try {
      await saveGame(game);
    } catch (err) {
      console.error('some sort of error with the game data save?', game, err);
    }
  },
  saveOutcomes: async (game, outcomes) => {
    try {
      await saveOutcomes(game, outcomes);
    } catch (err) {
      console.error('some sort of error with the game data save?', game, err);
    }
  },
  getNotes: async () => {
    try {
      return await getNotes();
    } catch (err) {
      console.log('some sort of error with the notes data save?', err);
    }
  },
  saveGameNotes: async (game, note) => {
    try {
      await saveNotes(game, note);
    } catch (err) {
      console.log('some sort of error with the notes data save?', note, err);
    }
  },
}

const gameActions = {
  addGame: (game) => () => gameApi.saveGameData(game),
  saveNotes: (game, note) => () => gameApi.saveGameNotes(game, note),
  updateOutcomes: (update) => (dispatch) => dispatch({
    type: ADD_OUTCOME,
    payload: update,
  }),
  fetchGames: (data) => async (dispatch) => {
    dispatch({type: FETCH_GAMES_START});

    const outcomes = await gameApi.getOutcomes();
    const gameNotes = await gameApi.getNotes();

    const enhancedData = data.map((game) => ({
      ...game,
      outcomes: outcomes[game.game],
      currentNotes: gameNotes[game.game] || '',
    }))

    dispatch({
      type: FETCH_GAMES_SUCCESS,
      payload: enhancedData,
    })
    return data;
  },
};

const gameReducer = (state, action) => {
  const {type, payload} = action;

  switch (type) {
    case FETCH_GAMES_START:
      return {
        gameData: [...state.gameData],
        isLoading: true,
      };
    case FETCH_GAMES_SUCCESS:
      return {
        ...state,
        isLoading: false,
        gameData: [...payload],
      };
    case ADD_OUTCOME:
      const update = state.gameData.filter(({game}) => game === payload.game)[0];
      const {outcomes} = update;
      outcomes.total += 1;
      outcomes.won += payload.iWon ? 1 : 0;
      payload.playerSet.forEach((p) => {
        if(!outcomes.players[p]) outcomes.players[p] = 0;
        outcomes.players[p] += 5;
      })
      gameApi.saveOutcomes(payload.game, {...outcomes});
      const newGameData = state.gameData.map((game) => {
        if (game.game !== payload.game) return game;
        return {
          ...game,
          outcomes,
        };
      })
      return {
        gameData: newGameData,
        isLoading: state.isLoading,
      }
    default:
      throw new Error('Unknown action type in game reducer')
  }
};

export {
  gameApi,
  gameActions,
  gameReducer,
};
