import React, { useMemo } from 'react';
import baseData from '../baseData';
import './gameInfo.css';

// () => [
//   { game: '7 Wonders', outcomes: [{me: 38, gl: 26, o1: 35, o2: 43, o3: 43, o4: 35, o5: 32}, {me: 50, ml: 37, gl: 47}, {me: 47, o3: 44, cl: 39, o6: 42, gl: 50, jl: 38, ml: 56}, {me: 46, gl: 39, nl: 31, wl: 28}, {cl: 37, gl: 43, sl: 36, me: 37, ml: 39, mace: 39}, {cl: 37, gl: 40, sl: 55, me: 35, ml: 59, mace: 36}, {cl: 47, gl: 36, sl: 39, me: 35, ml: 47}], qDate: '09/28/2022' },
//   { game: 'Splendor', outcomes: [{me: 14, ml: 17, gl: 10}], qDate: '09/28/2022' },
//   { game: 'Wingspan', outcomes: [{me: 90, ml: 84, gl: 76}, {me: 65, ml: 73}, {me: 86, wl: 73, ml: 67}, {me: 89, ml: 98, gl: 94}, {me: 88, mt: 82, at: 74, ml: 75}, {me: 93, ml: 92, mace: 81, cl: 75}], qDate: '09/29/2022' },
//   { game: 'Brass Birmingham', outcomes: [{me: 126, mq: 116, bk: 108, jj: 105}], qDate: '09/29/2022' },
//   { game: 'Dominant Species Marine', outcomes: [], qDate: '09/29/2022' },
//   { game: 'Acquire', outcomes: [{me: 683, ml: 432},{me: 610, ml: 508, gl: 529},{me: 244, ml: 207, gl: 346, nl: 164, wl: 348}], qDate: '09/29/2022' },
//   { game: 'Gaia Project', outcomes: [], qDate: '09/30/2022' },
//   { game: 'Great Western Trail', outcomes: [], qDate: '09/30/2022' },
//   { game: 'Ticket to Ride', outcomes: [{me: 121, es: 110}], qDate: '09/30/2022' },
//   { game: 'Ra', outcomes: [], qDate: '09/30/2022' },
//   { game: 'Azul', outcomes: [{me: 51, wl: 69}], qDate: '09/30/2022' },
//   { game: 'Catan', outcomes: [{me: 12, wl: 10, gl: 10, nl: 9}], qDate: '10/01/2022' },
//   { game: 'Blood Rage', outcomes: [], qDate: '10/01/2022' },
// ],

export default function GameInfo(props) {
  const {game} = props;

  const data = baseData.filter((bd) => bd.game === game)[0];
console.log(data);
  return (
    <>
      <div className='gi-width'>
        <strong>Game:</strong> <a href={data.wsbgUrl} target='_blank' rel='noreferrer'>{data.label}</a>
      </div>
      <div className='gi-width'>
        <strong>Rounds:</strong> {data.rounds} <strong>Seats:</strong> {data.seats}
      </div>
      <div className='gi-width'> <strong>Competition:</strong> {data.competition}</div>
      <div className='gi-width'> <strong>Description:</strong> {data.description}</div>
    </>
  )
}
