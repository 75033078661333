import React from "react";

const asyncDispatch = (dispatch) => (action) =>
  action instanceof Function ? action(dispatch) : dispatch(action);

const useAsyncReducer = (reducer, initialArg, init) => {
  const [state, syncDispatch] = React.useReducer(reducer, initialArg, init);
  const dispatch = React.useMemo(() => asyncDispatch(syncDispatch), []);
  return [state, dispatch];
};

export default useAsyncReducer;