import React from 'react';
import IconButton from '@mui/material/IconButton';
import SortByAlpha from '@mui/icons-material/SortByAlpha';
import EventTwoTone from '@mui/icons-material/EventTwoTone';
import './header.css';


export const sortButtonTypes = {
  alphabet: () => <SortByAlpha />,
  date: () => <EventTwoTone />,
}

export function SortButton(props) {
  const {current, type, onClick} = props;

  return (
    <IconButton
      aria-label={`Sort By ${type}`}
      variant='outlined'
      color={current ? 'secondary' : 'neutral'}
      onClick={onClick}
      size="large">{sortButtonTypes[type]()}</IconButton>
  );
}