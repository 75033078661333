import React, {useState, useCallback} from 'react';
import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import FormLabel from '@mui/material/FormLabel';
import TextField from '@mui/material/TextField';

const byWeight = (playerA, playerB) => playerB[1].weight - playerA[1].weight;

export default function Players(props) {
  const {onChange, potentials} = props;
  const [potentialPlayers, setPotentialPlayers] = useState(potentials)
  const [newPlayer, setNewPlayer] = useState('')

  const changeCb = useCallback(
    (potentials) => onChange('players', [...Object.entries(potentials)]
    .filter(([, player]) => player.selected)
    .map(([pid, ]) => pid)), [onChange]);

  const updatePlayer = useCallback((pid) => {
    setPotentialPlayers(p => {
      if(!p[pid]) p[pid] = {selected: false, weight: 1}
      p[pid].selected = !p[pid].selected;
      changeCb(p)
      return p;
    })
  },[setPotentialPlayers, changeCb])

  const submitNewPlayer = useCallback((event) => {
    if (event.keyCode === 13 || event.keyCode === 188 || event.type === 'blur') {
      event.stopPropagation();
      setNewPlayer( (newP= '') => {
        if (newP.length > 0)
          setPotentialPlayers(potPlay => {
            if (potPlay[newP]) {
              potPlay[newP].selected = true;
              changeCb(potPlay);
            } else {
              potPlay[newP] = {
                selected: false,
                weight: 1,
              }
            }
            return potPlay;
          })
        return '';
      });
    }
  }, [setPotentialPlayers, setNewPlayer, changeCb])

  const updateNewPlayer = useCallback(({target}) => {
    if (target.value !== ',') {
      setNewPlayer( target.value);
    }
  }, [setNewPlayer])

  const thePotentials = potentialPlayers ? [...Object.entries(potentialPlayers)].sort(byWeight) : [];

  return (
    <React.Fragment>
      <FormLabel>Players:</FormLabel>
      <Box>
        {thePotentials.map(([pid, player]) => (
            <Chip variant={player.selected ? 'default' : 'outlined'}
                  className='player-chips'
                  color='primary'
                  key={pid}
                  clickable
                  label={pid}
                  onClick={() => updatePlayer(pid)}
            />
          )
        )}
        <TextField fullWidth
                   value={newPlayer}
                   onKeyDown={submitNewPlayer}
                   onBlur={submitNewPlayer}
                   onChange={updateNewPlayer} />
      </Box>
    </React.Fragment>
  );
}