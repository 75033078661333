import React, { useState, useEffect } from 'react';
import { getAuth } from 'firebase/auth';

import UserContext from './userContext';
import Header from './Header/header';
import GameTable from './GameTable';
import GameDetail from './GameDetail';
import './App.css';

import { ThemeProvider, StyledEngineProvider, createTheme } from '@mui/material/styles';
import { green } from '@mui/material/colors';

const theme = createTheme({
  palette: {
    primary: {
      main: '#246eee',
    },
    secondary: {
      main: green[500],
    },
    neutral: {
      main: '#555',
    },
  },
});

function App() {
  const [sort, setSort] = useState('alphabet');
  const [page, setPage] = useState('GameTable');
  const [initialized, setInitialized] = useState(null);
  const changeSort = (s) => setSort(s);

  useEffect(() => {
    getAuth().onAuthStateChanged((user) => {
      setInitialized(user)
    })
  }, [setInitialized]);

  const gotoPage = (newPage) => setPage(newPage);

  return (
    <div className="App">
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Header sort={sort} updateSort={changeSort}/>
          {initialized && <UserContext.Provider value={initialized}>
            {page === 'GameTable' &&
            <GameTable sort={sort}
                       openDetails={gotoPage}
            />
            }
            {page !== 'GameTable' &&
            <GameDetail game={page}/>
            }
          </UserContext.Provider>
          }
        </ThemeProvider>
      </StyledEngineProvider>
    </div>
  );
}

export default App;
