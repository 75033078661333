import React from 'react';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';

export default function GameState(props) {
  const {gameEnd: {players, scores}} = props;

  const numberOfEntries = Math.max(players.length, scores.length)

  const playerScores = Array.from({length: numberOfEntries}, (x, i) => ({p: players[i] || `unk${i}`, s: scores[i]}))

  return (
    <React.Fragment>
      <Box sx={{height: '140px'}}>
        {numberOfEntries === 0 && (<Typography style={{fontStyle: 'italic'}}>No Scores</Typography>)}
        {playerScores.map(({p, s}) => (
          <Typography key={p} className='game-result-entries'>
            {p} -- {s}
          </Typography>
        ))}
      </Box>
    </React.Fragment>
  );
}